.layout-navigation {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
  justify-content: space-between;
}

.layout-container {
  display: flex;
  flex-direction: row;
}

.layout-list-container {
  height: calc(100vh - 7rem);
  flex: 2;
  /* flex-wrap: wrap; */
}

.layout-selected-item-container {
  height: calc(100vh -7rem);
  flex: 2;
}

.layout-selected-item-container-full-screen {
  height: calc(100vh - 7rem);
  flex: 9999;
}
