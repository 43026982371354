.avatar {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.markerinfo {
  /* margin: 0.5rem 0; */
  color: var(--color-accent-700);
  background-color: var(--color-primary-400);
  font-size: 0.75rem;
  padding: 0 0.2rem;
  border: 1px solid var(--color-accent-700);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  height: 30px;
  width:auto;
 
  top: -15px;
  left: 15px;
  position:absolute;
 
}