.adminNavigationHeader {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background:white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.adminpageContainer {
    display: flex;
    flex-direction: row;
  }

  .adminListContainer {
    height: calc(100vh - 7rem);
    flex: 2;
    /* flex-wrap: wrap; */
  }

  .adminUpdateContainer {
    height: calc(100vh -7rem);
    flex: 2;
    /* flex-wrap: wrap; */
  }

  .liveListContainer {
    height: calc(100vh - 7rem);
    flex: 1;
    /* flex-wrap: wrap; */
  }
  .liveUpdateContainer {
    height: calc(100vh -7rem);
    flex: 3;
    /* flex-wrap: wrap; */
  }
  