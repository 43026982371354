.itemListContainer {
  height: 100%;
  flex: 1;
  /* flex-wrap: wrap; */
}


.travelStatusLiveIndicator
{
  display: flex;
  align-items: center;
  /* background-color: yellow; */
  justify-content:center;
  text-align: center;
}