.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 10rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}

.login-failed {
  color: red;
  margin-bottom: 1rem;
  text-align: left;
}

.auth-message-success {
  color: var(--color-accent);
  margin-bottom: 1rem;
  text-align: left;
}

.auth-main-icon {
  width: 4rem;
  height: 4rem;
  color: var(--color-accent);
}

.auth-bottom-container {
  display: flex;
  flex-direction: column;
}

.auth-links-container {
  margin-top: 0;
  margin-bottom: 0;
}
