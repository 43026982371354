@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;

  --color-primary: #fbdd04;
  --color-accent: #0b0c0b;
  --color-surface: white;
  --color-solid: #1e1e1e;

  --color-primary-20: #fdef8b;
  --color-primary-500: #fcea68;
  --color-primary-100: #fce545;
  --color-primary-200: #fbdd04;
  --color-primary-300: #fbe122;
  --color-primary-400: #f5d803;
  --color-primary-500: #d2b903;
  --color-primary-600: #af9a02;
  --color-primary-700: #8c7b02;
  --color-primary-800: #695c01;
  --color-primary-900: #463d01;

  --color-accent-25: #c1c6c1;
  --color-accent-50: #afb5af;
  --color-accent-100: #9ca49c;
  --color-accent-200: #899389;
  --color-accent-300: #778277;
  --color-accent-400: #666f66;
  --color-accent-500: #555d55;
  --color-accent-600: #444a44;
  --color-accent-700: #333733;
  --color-accent-800: #222522;
  --color-accent-900: #0b0c0b;

  --color-success-50: #f0fdf4;
  --color-success-100: #dcfce7;
  --color-success-200: #bbf7d0;
  --color-success-300: #86efac;
  --color-success-400: #4ade80;
  --color-success-500: #22c55e;
  --color-success-600: #16a34a;
  --color-success-700: #15803d;
  --color-success-800: #166534;
  --color-success-900: #14532d;

  --color-error-50: #fef2f2;
  --color-error-100: #fee2e2;
  --color-error-200: #fecaca;
  --color-error-300: #fca5a5;
  --color-error-400: #f87171;
  --color-error-500: #ef4444;
  --color-error-600: #dc2626;
  --color-error-700: #b91c1c;
  --color-error-800: #991b1b;
  --color-error-900: #7f1d1d;

  --color-link: #3182ce;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  background: white;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 7rem);
}

.horizontal-line {
  width: 100%;
  height: 2px;
  background: var(--color-accent-100);
  margin: 2rem 0;
}

@media print {
  body {
    visibility: hidden;
  }
  #printableArea {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-print-color-adjust: exact;
  }
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26) !important;
  border-radius: 3px !important;
  background: white;
  height: 100% !important;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  display: block !important;
  margin-top: 5rem !important;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.modalbuttonscontainer {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 30px;
  column-gap: 30px;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.new-button-container {
  justify-content: flex-start;
  display: inline-flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.generate-success {
  flex-direction: column;
  grid-gap: 36px;
  gap: 36px;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.livepagecontainer {
  flex-direction: row;
  display: flex;
  top: 12px;
  /* margin: 10px; */
  outline: none;
  width: 100%;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.routeInfoContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMapContainer {
  /* height: calc(100vh); */
  height: calc(50vh);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMap {
  height: calc(50vh);
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.routeInfoDetails {
  padding-top: 100px;
  display: flex;
  display: inline-block;
  flex-direction: row;
  /* height: calc(100vh -7rem); */
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  /* flex-wrap: wrap; */
}

.itemListContainer {
  height: 100%;
  flex: 1 1;
  /* flex-wrap: wrap; */
}


.travelStatusLiveIndicator
{
  display: flex;
  align-items: center;
  /* background-color: yellow; */
  justify-content:center;
  text-align: center;
}

.processongoing
{
  border: 1px solid darkred;
  background-color: darkred;
  color: white;
  font-size: medium;
  font-weight: bold;
  text-align: center !important;
  border-radius: 20px;
}

.processdone
{
  border: 1px solid darkblue;
  background-color: darkblue;
  color: white;
  font-size: medium;
  font-weight: bold;
  text-align: center !important;
  border-radius: 20px;
}


.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.report-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.groupContainer {
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border: 5px solid #f47174;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.groupContainer-accounting {
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border: 5px solid #f47174;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.new-item-form-accounting {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.item-form-accounting {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.form-control {
    margin: 1rem 0;
  }
  
  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }
  
  .form-control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-control input,
  .form-control textarea {
    width: 100%;
    font: inherit;
    border: 1px solid #ccc;
    background: #f8f8f8;
    padding: 0.15rem 0.25rem;
  }
  
  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border-color: #510077;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }
.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}

p {
  margin-top: 0.5rem;
}

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--color-accent);
  border-radius: 0.25rem;
  /* background: #ff0055; */
  background: var(--color-accent);
  color: var(--color-primary);
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  /* background: #ff4382;
  border-color: #ff4382; */
  background: var(--color-accent-700);
  border-color: var(--color-accent-700);
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--loading {
  margin-left: 1rem;
  /* margin-right: 0.5rem; */
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  color: white;
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  color: white;
  background: #b30000;
  border-color: #b30000;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
}

.button--big {
  font-size: 1.5rem;
}

.loading {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  padding-left: 0.5rem;
}

.loading:after {
  content: " ";
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.125rem solid var(--color-accent);
  border-color: var(--color-accent) var(--color-accent) var(--color-accent) transparent;
  animation: loading 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control {
    margin: 1rem 0;
  }


  .datepickerclass{
    width: 100%;
    height: 30px;
  }

  
  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }
  
  .form-control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-control input,
  .form-control textarea {
    width: 100%;
    font: inherit;
    border: 1px solid #ccc;
    background: #f8f8f8;
    padding: 0.15rem 0.25rem;
  }
  
  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border-color: #510077;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  z-index: 100;
  position: fixed;
  top: 11vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 1490px) {
  .modal {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.itemListContainer {
  height: 100%;
  flex: 1 1;
  /* flex-wrap: wrap; */
}


.travelStatusLiveIndicator
{
  display: flex;
  align-items: center;
  /* background-color: yellow; */
  justify-content:center;
  text-align: center;
}
.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.chart {
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle {
  margin-bottom: 20px;
}

.adminNavigationHeader {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.adminpageContainer {
  display: flex;
  flex-direction: row;
}

.adminListContainer {
  height: calc(100vh - 7rem);
  flex: 2 1;
  /* flex-wrap: wrap; */
}

.adminUpdateContainer {
  height: calc(100vh -7rem);
  flex: 2 1;
  /* flex-wrap: wrap; */
}

.ikarusListContainer {
  height: calc(100vh - 7rem);
  flex: 1 1;
  /* flex-wrap: wrap; */
}
.ikarusUpdateContainer {
  height: calc(100vh -7rem);
  flex: 1 1;
  /* flex-wrap: wrap; */
}

.avatar {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.markerinfo {
  /* margin: 0.5rem 0; */
  color: var(--color-accent-700);
  background-color: var(--color-primary-400);
  font-size: 0.75rem;
  padding: 0 0.2rem;
  border: 1px solid var(--color-accent-700);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  height: 30px;
  width:auto;
 
  top: -15px;
  left: 15px;
  position:absolute;
 
}
.adminNavigationHeader {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.adminpageContainer {
  display: flex;
  flex-direction: row;
}

.adminListContainer {
  height: calc(100vh - 7rem);
  flex: 2 1;
  /* flex-wrap: wrap; */
}

.adminUpdateContainer {
  height: calc(100vh -7rem);
  flex: 2 1;
}

.liveListContainer {
  height: calc(100vh - 7rem);
  flex: 1 1;
  /* flex-wrap: wrap; */
}
.liveUpdateContainer {
  height: calc(100vh -7rem);
  flex: 3 1;
  /* flex-wrap: wrap; */
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.report-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 5px solid #f47174;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.horizontal-slider {
  width: 100%;
  height: 50px;
  color: black;
  margin-top: 5px;
  margin-right: 10px;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid black;
  margin-top: 5px;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
  background-color: gray;
}

.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: black;
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.shared-help-footer {
  text-align: right;
  margin-right: 1rem;
}

.shared-help-content {
  margin: 2rem;
  margin-top: 0;
}

.layout-navigation {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
  justify-content: space-between;
}

.layout-container {
  display: flex;
  flex-direction: row;
}

.layout-list-container {
  height: calc(100vh - 7rem);
  flex: 2 1;
  /* flex-wrap: wrap; */
}

.layout-selected-item-container {
  height: calc(100vh -7rem);
  flex: 2 1;
}

.layout-selected-item-container-full-screen {
  height: calc(100vh - 7rem);
  flex: 9999 1;
}

.shared-service-map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.shared-service-map-container-big {
  height: 45rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.report-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 5px solid #f47174;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.itemListContainer {
  height: 100%;
  flex: 1 1;
  /* flex-wrap: wrap; */
}


.travelStatusLiveIndicator
{
  display: flex;
  align-items: center;
  /* background-color: yellow; */
  justify-content:center;
  text-align: center;
}
.header-buttons-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
  margin-bottom: 10px;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 3px solid #f47174;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.item-form-route {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  padding-left: 0;
  width: 100%;
  /* max-width: 40rem; */
  background: white;
}

.groupContainerRoute {
  border-radius: 5px;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;

  margin-top: 10px;

  margin-right: 30px;
  width: 40%;
  display: inline-block;
  vertical-align: top;
}

.transferRouteItemStyle {
  /* border-radius: 5px;
  border-width: 1px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */

  /* margin-top: 10px; */
  /* margin-right: 30px; */
  width: 15%;
  /* display: inline-block; */
}

.transferRouteItemButton {
  top: 10px;
  position: relative;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow-y: auto;
  display: inline-block;
}

.excel-transfer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-transfer-item {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow-y: auto;
  display: inline-block;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.livepagecontainer {
  flex-direction: row;
  display: flex;
  top: 12px;
  /* margin: 10px; */
  outline: none;
  width: 100%;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.routeInfoContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMapContainer {
  /* height: calc(100vh); */
  height: calc(50vh);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMap {
  height: calc(50vh);
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.routeInfoDetails {
  padding-top: 100px;
  display: flex;
  display: inline-block;
  flex-direction: row;
  /* height: calc(100vh -7rem); */
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  /* flex-wrap: wrap; */
}

.pRoutePersonelContainer {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.personellist-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.personellist-li {
  margin: 0.5rem 0;
}

.mainContainerhere {
  display: flex;
  flex-direction: row;
}

.adminNavigationHeader {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background:white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.adminpageContainer {
    display: flex;
    flex-direction: row;
  }

  .adminListContainer {
    height: calc(100vh - 7rem);
    flex: 2 1;
    /* flex-wrap: wrap; */
  }

  .adminUpdateContainer {
    height: calc(100vh -7rem);
    flex: 2 1;
    /* flex-wrap: wrap; */
  }

  .liveListContainer {
    height: calc(100vh - 7rem);
    flex: 1 1;
    /* flex-wrap: wrap; */
  }
  .liveUpdateContainer {
    height: calc(100vh -7rem);
    flex: 3 1;
    /* flex-wrap: wrap; */
  }
  
.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid whitesmoke;
  }
  
  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-top: 5px solid whitesmoke;
  }
.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 13rem;
  height: 13rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itemListContainer {
  height: 100%;
  flex: 1 1;
  /* flex-wrap: wrap; */
}


.travelStatusLiveIndicator
{
  display: flex;
  align-items: center;
  /* background-color: yellow; */
  justify-content:center;
  text-align: center;
}
.header-buttons-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
  margin-bottom: 10px;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 3px solid #f47174;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.item-form-route {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  padding-left: 0;
  width: 100%;
  /* max-width: 40rem; */
  background: white;
}

.groupContainerRoute {
  border-radius: 5px;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;

  margin-top: 10px;

  margin-right: 30px;
  width: 40%;
  display: inline-block;
  vertical-align: top;
}

.transferRouteItemStyle {
  /* border-radius: 5px;
  border-width: 1px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */

  /* margin-top: 10px; */
  /* margin-right: 30px; */
  width: 15%;
  /* display: inline-block; */
}

.transferRouteItemButton {
  top: 10px;
  position: relative;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow-y: auto;
  display: inline-block;
}

.excel-transfer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-transfer-item {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow-y: auto;
  display: inline-block;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.livepagecontainer {
  flex-direction: row;
  display: flex;
  top: 12px;
  /* margin: 10px; */
  outline: none;
  width: 100%;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.routeInfoContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMapContainer {
  /* height: calc(100vh); */
  height: calc(50vh);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMap {
  height: calc(50vh);
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.routeInfoDetails {
  padding-top: 100px;
  display: flex;
  display: inline-block;
  flex-direction: row;
  /* height: calc(100vh -7rem); */
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  /* flex-wrap: wrap; */
}

.pRoutePersonelContainer {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.personellist-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.personellist-li {
  margin: 0.5rem 0;
}

.mainContainerhere {
  display: flex;
  flex-direction: row;
}

.adminNavigationHeader {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background:white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.adminpageContainer {
    display: flex;
    flex-direction: row;
  }

  .adminListContainer {
    height: calc(100vh - 7rem);
    flex: 2 1;
    /* flex-wrap: wrap; */
  }

  .adminUpdateContainer {
    height: calc(100vh -7rem);
    flex: 2 1;
    /* flex-wrap: wrap; */
  }

  .ikarusListContainer {
    height: calc(100vh - 7rem);
    flex: 1 1;
    /* flex-wrap: wrap; */
  }
  .ikarusUpdateContainer {
    height: calc(100vh -7rem);
    flex: 1 1;
    /* flex-wrap: wrap; */
  }
  
.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.report-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 5px solid #f47174;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.generic-error {
  position: absolute;
  margin: auto;
  margin-top: 5rem;
  width: 50%;
  background-color: #ffc107;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  right: 0;
  top: 0.5rem;

  .warning-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .text {
    margin-left: 1rem;
  }

  .close-icon-container {
    position: absolute;
    right: 0.5rem;
    cursor: pointer;
    display: flex;
  }
  .close-icon {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.link {
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  /* color: #007bff; */
  color: var(--color-link);
  text-decoration: underline;
  cursor: pointer;
}

.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 10rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}

.login-failed {
  color: red;
  margin-bottom: 1rem;
  text-align: left;
}

.auth-message-success {
  color: var(--color-accent);
  margin-bottom: 1rem;
  text-align: left;
}

.auth-main-icon {
  width: 4rem;
  height: 4rem;
  color: var(--color-accent);
}

.auth-bottom-container {
  display: flex;
  flex-direction: column;
}

.auth-links-container {
  margin-top: 0;
  margin-bottom: 0;
}

.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  /* background: #ff0055; */
  background: var(--color-accent);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
  grid-gap: 14px;
  gap: 14px;
  justify-content: space-between;
}

main {
  margin-top: 4rem;
}

@media (max-width: 1100px) {
  .main-header {
    justify-content: space-between;
    grid-gap: 22px;
    gap: 22px;
    justify-content: flex-start;
    padding: 0;
    padding-left: 1rem;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links li:last-of-type {
  margin-left: auto;
  margin-right: 15px;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  /* background: #f8df00; */
  background: var(--color-primary-400);
  border-color: #292929;
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 1100px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    /* background: #f8df00; */
    background: var(--color-primary);
    color: #292929;
  }
}

@media (max-width: 1100px) {
  .nav-links {
    flex-direction: column;
  }

  .nav-links li {
    margin: 20px 0.5rem;
  }

  .nav-links li:last-of-type {
    margin: 20px 0.5rem;
    margin-top: 100px;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: var(--color-primary);
    color: #292929;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 40%;
  background: var(--color-accent);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.main-navigation__menu-btn {
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  align-self: baseline;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;

  .nav-links {
    justify-content: flex-start;
    margin-top: 50px;
  }
}

@media (min-width: 1100px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
    flex: 1 1;
  }
}

.user-circle {
  height: 25px;
  width: 25px;
}

.language-area {
  background: var(--color-accent) none repeat scroll 0 0;
  color: #eee;
}

@media (max-width: 1100px) {
  .language-area {
    margin: auto;
    margin-right: 20px;
  }
}

.header-top-right {
  text-align: right;
  padding: 10px 0;
}

.header-top-right > .dropdown {
  display: inline-block;
}

.header-top-right > .dropdown button {
  background: transparent;
  color: #eee;
  border: medium none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.header-top-right > .dropdown .dropdown-menu {
  flex-direction: column;
  display: none;
  border-radius: 0;
  border: medium none;
  background: var(--color-accent) none repeat scroll 0 0;
  color: #fff;
  margin: 0;
  left: auto !important;
  right: 0 !important;
  padding: 0;
  min-width: 200px;
  transform: translate(0px, 64px) !important;
  text-align: center;
  grid-gap: 15px;
  gap: 15px;
}

.header-top-right > .dropdown .dropdown-menu.show {
  display: flex !important;
  padding-top: 10px;
}

.header-top-right > .dropdown .dropdown-menu a {
  padding: 5px 10px;
  border-bottom: 1px solid #444;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  background-color: var(--color-accent);
  text-align: left;
  text-decoration: none;
}

.header-top-right > .dropdown .dropdown-menu button {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
  text-decoration: underline;
}

.header-top-right > .dropdown .dropdown-menu a:hover {
  background: var(--color-accent) none repeat scroll 0 0;
}

.header-top-right > .dropdown .dropdown-menu li img {
  margin-right: 5px;
}

.header-top-right > a {
  color: #eee;
  text-transform: capitalize;
  margin-right: 13px;
}
.header-top-right > a svg {
  margin-right: 5px;
}

.gauto-header-top-area {
  margin-bottom: 0;
}

.gauto-header-top-area {
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #eee;
}

.gauto-header-top-area {
  margin-bottom: 0;
}

.gauto-header-top-area {
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #eee;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.report-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 5px solid #f47174;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.header-buttons-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
}

.item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.groupContainer {
  border-radius: 5px;
  border-width: 1px;
  border: 3px solid #f47174;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 30px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.item-form-route {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  padding-left: 0;
  width: 100%;
  /* max-width: 40rem; */
  background: white;
}

.groupContainerRoute {
  border-radius: 5px;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;

  margin-top: 10px;

  margin-right: 30px;
  width: 40%;
  display: inline-block;
  vertical-align: top;
}

.transferRouteItemStyle {
  /* border-radius: 5px;
  border-width: 1px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */

  /* margin-top: 10px; */
  /* margin-right: 30px; */
  width: 15%;
  /* display: inline-block; */
}

.transferRouteItemButton {
  top: 10px;
  position: relative;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.datepickerclass {
  width: 100%;
  height: 50px;
}

.new-item-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 50%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow-y: auto;
  display: inline-block;
}

.excel-transfer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-transfer-item {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow-y: auto;
  display: inline-block;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
}

.routeInfoStudents {
  display: flex;

  margin: 10px;
  margin-top: 75px;
  flex-wrap: wrap;
}

.horizontal-slider {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.livepagecontainer {
  flex-direction: row;
  display: flex;
  top: 12px;
  /* margin: 10px; */
  outline: none;
  width: 100%;
}

.liveheadercontainer {
  width: 300px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}
.liveheaderdatecontainer {
  margin-top: 10px;
}

.routetrackcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeplayercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.routeInfoContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMapContainer {
  /* height: calc(100vh); */
  height: calc(50vh);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  /* flex-wrap: wrap; */
}

.routeInfoMap {
  height: calc(50vh);
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.routeInfoDetails {
  padding-top: 100px;
  display: flex;
  display: inline-block;
  flex-direction: row;
  /* height: calc(100vh -7rem); */
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  /* flex-wrap: wrap; */
}

.pRoutePersonelContainer {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.personellist-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.personellist-li {
  margin: 0.5rem 0;
}

.mainContainerhere {
  display: flex;
  flex-direction: row;
}

.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid whitesmoke;
  }
  
  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-top: 5px solid whitesmoke;
  }
.adminNavigationHeader {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background:white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.adminpageContainer {
    display: flex;
    flex-direction: row;
  }

  .adminListContainer {
    height: calc(100vh - 7rem);
    flex: 2 1;
    /* flex-wrap: wrap; */
  }

  .adminUpdateContainer {
    height: calc(100vh -7rem);
    flex: 2 1;
    /* flex-wrap: wrap; */
  }

  .liveListContainer {
    height: calc(100vh - 7rem);
    flex: 1 1;
    /* flex-wrap: wrap; */
  }
  .liveUpdateContainer {
    height: calc(100vh -7rem);
    flex: 3 1;
    /* flex-wrap: wrap; */
  }
  
