.horizontal-slider {
  width: 100%;
  height: 50px;
  color: black;
  margin-top: 5px;
  margin-right: 10px;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid black;
  margin-top: 5px;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
  background-color: gray;
}

.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: black;
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
