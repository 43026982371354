.generic-error {
  position: absolute;
  margin: auto;
  margin-top: 5rem;
  width: 50%;
  background-color: #ffc107;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  right: 0;
  top: 0.5rem;

  .warning-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .text {
    margin-left: 1rem;
  }

  .close-icon-container {
    position: absolute;
    right: 0.5rem;
    cursor: pointer;
    display: flex;
  }
  .close-icon {
    height: 1.3rem;
    width: 1.3rem;
  }
}
