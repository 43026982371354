.link {
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  /* color: #007bff; */
  color: var(--color-link);
  text-decoration: underline;
  cursor: pointer;
}
