.main-navigation__menu-btn {
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  align-self: baseline;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;

  .nav-links {
    justify-content: flex-start;
    margin-top: 50px;
  }
}

@media (min-width: 1100px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
    flex: 1;
  }
}

.user-circle {
  height: 25px;
  width: 25px;
}

.language-area {
  background: var(--color-accent) none repeat scroll 0 0;
  color: #eee;
}

@media (max-width: 1100px) {
  .language-area {
    margin: auto;
    margin-right: 20px;
  }
}

.header-top-right {
  text-align: right;
  padding: 10px 0;
}

.header-top-right > .dropdown {
  display: inline-block;
}

.header-top-right > .dropdown button {
  background: transparent;
  color: #eee;
  border: medium none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-top-right > .dropdown .dropdown-menu {
  flex-direction: column;
  display: none;
  border-radius: 0;
  border: medium none;
  background: var(--color-accent) none repeat scroll 0 0;
  color: #fff;
  margin: 0;
  left: auto !important;
  right: 0 !important;
  padding: 0;
  min-width: 200px;
  transform: translate(0px, 64px) !important;
  text-align: center;
  gap: 15px;
}

.header-top-right > .dropdown .dropdown-menu.show {
  display: flex !important;
  padding-top: 10px;
}

.header-top-right > .dropdown .dropdown-menu a {
  padding: 5px 10px;
  border-bottom: 1px solid #444;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  background-color: var(--color-accent);
  text-align: left;
  text-decoration: none;
}

.header-top-right > .dropdown .dropdown-menu button {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
  text-decoration: underline;
}

.header-top-right > .dropdown .dropdown-menu a:hover {
  background: var(--color-accent) none repeat scroll 0 0;
}

.header-top-right > .dropdown .dropdown-menu li img {
  margin-right: 5px;
}

.header-top-right > a {
  color: #eee;
  text-transform: capitalize;
  margin-right: 13px;
}
.header-top-right > a svg {
  margin-right: 5px;
}

.gauto-header-top-area {
  margin-bottom: 0;
}

.gauto-header-top-area {
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #eee;
}

.gauto-header-top-area {
  margin-bottom: 0;
}

.gauto-header-top-area {
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #eee;
}
