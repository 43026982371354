.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--color-accent);
  border-radius: 0.25rem;
  /* background: #ff0055; */
  background: var(--color-accent);
  color: var(--color-primary);
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  /* background: #ff4382;
  border-color: #ff4382; */
  background: var(--color-accent-700);
  border-color: var(--color-accent-700);
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--loading {
  margin-left: 1rem;
  /* margin-right: 0.5rem; */
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  color: white;
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  color: white;
  background: #b30000;
  border-color: #b30000;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
}

.button--big {
  font-size: 1.5rem;
}

.loading {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  padding-left: 0.5rem;
}

.loading:after {
  content: " ";
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.125rem solid var(--color-accent);
  border-color: var(--color-accent) var(--color-accent) var(--color-accent) transparent;
  animation: loading 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
