.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  /* background: #ff0055; */
  background: var(--color-accent);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
  gap: 14px;
  justify-content: space-between;
}

main {
  margin-top: 4rem;
}

@media (max-width: 1100px) {
  .main-header {
    justify-content: space-between;
    gap: 22px;
    justify-content: flex-start;
    padding: 0;
    padding-left: 1rem;
  }
}
